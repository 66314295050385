<template>
    <common-page-header v-if="selected"
                        :main-text="selected.name"
                        :sub-text="selected.email"
    >
        <template v-slot:icon>
            <span class="fas fa-user page-detail-header-icon"></span>
        </template>
        <template v-slot:buttons>
            <b-btn variant="info">Resetuj hasło</b-btn>
        </template>
    </common-page-header>
</template>

<script>
    import CommonPageHeader from '../Common/PageHeader.vue'
    import { mapGetters } from 'vuex'
    import * as TYPES from "../../store/types";

    export default {
        name: "PageHeader",
        components: {
            CommonPageHeader
        },

        computed: {
            ...mapGetters({
                selected: [TYPES.SYSTEMUSERS_G_GET_SELECTED],
            }),
        }
    }
</script>

<style scoped>
    .page-detail-header-icon {
        font-size:64px;
        margin-left:auto;
        margin-right: auto;
    }
</style>
