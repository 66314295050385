
<template>
    <div v-if="edited">
        <ValidationObserver v-slot="{ handleSubmit }">
            <div class="row">
                <div class="col col-md-6 col-sm-12">
                    <create-password-field v-model="edited.password" label="Nowe hasło" :errors="[]" ></create-password-field>
                </div>
            </div>

            <div class="row">
                <div class="col text-end">
                    <b-btn variant="success" @click="handleSubmit(onSave)">zapisz</b-btn>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import * as TYPES from '@/store/types';
import * as PRIVILEGES from '@/config/Privileges'
import InputField from '@/components/Forms/InputField'
import CreatePasswordField from "@/components/Forms/CreatePasswordField";
import {mapGetters} from 'vuex'
import swal from 'sweetalert2'
import axios from 'axios';

export default {
    name: "DetailsPage_data",
    components: {
        InputField,
        CreatePasswordField
    },
    props: {
    },
    watch: {
    },
    data() {
        return {
            edited: {password: ''},
            fileIcon: null,
            PRIVILEGES
        }
    },
    computed: {
        ...mapGetters({
            authUser: [TYPES.AUTH_USER_GET_DATA]
        }),
        readonly() {
            return false;
        }
    },
    methods: {
        onSave() {
            this.$store.dispatch(TYPES.AUTH_USER_SET_NEW_PASSWORD, {password: this.edited.password})
                .then( (saved) => {
                    this.$store.dispatch(TYPES.AUTH_USER_LOAD)

                });
        }
    },
    created() {

    },
    mounted() {
    },
}
</script>

<style scoped>

</style>
