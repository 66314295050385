
<template>
    <div v-if="edited">
        <ValidationObserver v-slot="{ handleSubmit }">
            <div class="row">
                <div class="col col-md-6 col-sm-12">
                    <InputField type="text"
                                label="Imię Nazwisko"
                                name="Imię Nazwisko"
                                v-model="edited.name"
                                label-class="required"
                                rules="required"
                                :readonly="readonly"/>
                </div>
                <div class="col col-md-6 col-sm-12">
                    <InputField type="text"
                                label="Email"
                                name="Email"
                                v-model="edited.email"
                                label-class="required"
                                rules="required|email"
                                :readonly="readonly || selected.id > 0"/>
                </div>
            </div>

            <div class="row">
                <div class="col text-end">
                    <b-btn variant="success" @click="handleSubmit(onSave)">zapisz</b-btn>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
import * as TYPES from '@/store/types';
import * as PRIVILEGES from '@/config/Privileges'
import InputField from '@/components/Forms/InputField'
import {mapGetters} from 'vuex'
import swal from 'sweetalert2'
import axios from 'axios';

export default {
    name: "DetailsPage_data",
    components: {
        InputField
    },
    props: {
        selected: {
            required: true
        }
    },
    watch: {
        selected(newVal, oldVal) {
            this.edited = JSON.parse(JSON.stringify(newVal))
        }
    },
    data() {
        return {
            edited: null,
            fileIcon: null,
            PRIVILEGES
        }
    },
    computed: {
        ...mapGetters({
            authUser: [TYPES.AUTH_USER_GET_DATA]
        }),
        readonly() {
            return false;
        }
    },
    methods: {
        onSave() {
            this.$store.dispatch(TYPES.AUTH_USER_SAVE, this.edited)
                .then( (saved) => {
                    this.$store.dispatch(TYPES.AUTH_USER_LOAD)

                });
        }
    },
    created() {

    },
    mounted() {
        console.log('details tab')
        this.edited = JSON.parse(JSON.stringify(this.selected));
    },
}
</script>

<style scoped>

</style>
