<template>
    <common-details-page title="Twój profil"
                         :tabs="[
                      {title: 'Dane', active: true, slotName: 'dataTab'},
                      {title: 'Awatar', active: false, slotName: 'avatarTab'},
                      {title: 'Hasło', active: false, slotName: 'passwordTab'},
                  ]"
    >
        <template v-slot:header>
            <page-header></page-header>
        </template>
        <template v-slot:dataTab>
            <data-tab :selected="selected" v-if="selected"></data-tab>
        </template>
        <template v-slot:avatarTab>
            <avatar-tab :selected="selected"></avatar-tab>
        </template>
        <template v-slot:passwordTab>
            <password-tab :selected="selected"></password-tab>
        </template>
    </common-details-page>
</template>

<script>
import CommonDetailsPage from "@/pages/Common/DetailsPage";
import {mapGetters} from "vuex";
import * as TYPES from '@/store/types';
import DataTab from './ProfilePage_data'
import AvatarTab from './ProfilePage_avatar'
import PasswordTab from './ProfilePage_password'
import PageHeader from "./PageHeader";
export default {
    name: "SystemUsersDetailsPage",
    components: {
        PageHeader,
        CommonDetailsPage,
        DataTab,
        AvatarTab,
        PasswordTab,
    },
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters({
            selected: [TYPES.AUTH_USER_GET_DATA]
        })
    },
    created() {

    }
}
</script>

<style scoped>

</style>
